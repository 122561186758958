import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

// import "./App.css";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
// import { Cookies, useCookies } from "react-cookie";
import axios from "axios";

function App() {
  // const [cookies, setCookie ] = useCookies(["msisdn"]);
  // console.log("window.location hostname ", window.location.hostname);

  let location = useLocation();

  // console.log("window.location ", window.location.pathname.toLowerCase());
  React.useEffect(() => {
   
    return () => window.scrollTo({ top: 0});
    
  }, [location]);
    // console.log(useContext(userContext));
  console.log("window.location.href ", window.location.pathname);

  return <div></div>;
}

export default App;
