import React from "react";
import {  useCookies } from "react-cookie";
import {  useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../ErrorModal/ErrorModal";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
  

// import UserState from "../context/UserState";

const Layout = (props) => {
  let location = useLocation();
  const [cookies, setCookie] = useCookies(["msisdn"]);
  const navigate = useNavigate();
  React.useEffect(() => {
    if(cookies.msisdn !== "null" && cookies.msisdn){
      navigate("/redirect");
    } else {
     // navigate("")
    }
  }, []);
 
  
  return (
    <div>
      <ToastContainer 
     position="bottom-right"
     autoClose={5000}
     hideProgressBar={false}
     newestOnTop={false}
     closeOnClick
     rtl={false}
     pauseOnFocusLoss
     draggable
     pauseOnHover
     theme="light"
      />
      {!location.pathname.includes("home") ? <Header /> : null}
      {/* {cookies.ani === "null" && location.pathname === "/redirect" ? (
        <ErrorModal />
      ) : null} */}
      <div className="content">{props.children}</div>

      {/* {!location.pathname.includes("home") ? <Footer /> : null} */}
    </div>
  );
};

export default Layout;
