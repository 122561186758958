import { Spin } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Main from "../../components/Main/Main";
import logogamofyy from "../../assets/images/logo.png";
import igpl from "../../assets/images/GPL.png";
import axios from "axios";
// import ModalView from "../../components/Modal/Moda";
import { useCookies } from "react-cookie";


const HomePage = () => {
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cookies, setCookie] = useCookies(["msisdn"]);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  console.log(cookies, "this is cookies here --==>>");

  
  const params = useParams();
  // console.log(params);
  const search = useLocation().search;
  let ext_id = new URLSearchParams(search).get("ext_id");
  // console.log("ext_id ", ext_id);

  const queryParams = new URLSearchParams(window.location.search);
  

 

  return (
    <StyledHomePage>
      <Suspense
        fallback={
          <>
            <Spin />
          </>
        }
      >
        {params.slug === "igpl" ? (
          <Main 
          logo={igpl} 
          serviceType={params.slug} ext_id={ext_id} />
        ) : (
          <>
            <Main
              serviceType={params.slug}
              logo={logogamofyy}
              ext_id={ext_id}
              msisdn={queryParams.get("msisdn")}
              network={queryParams.get("network")}
            />
          </>
        )}
      </Suspense>
    </StyledHomePage>
  );
};

export default HomePage;

const StyledHomePage = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
`;
